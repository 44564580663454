import React from 'react';
import logo from './logo.svg';
import appstoreButton from './appstore-button.svg';
import './buttons.css';

export default () => {
    return (
        <div className="button-container">
            <img className="logo" src={logo} alt="logo" />
            <a href="https://apps.apple.com/us/app/getitdone-to-do-wallpaper/id1495989242?mt=8">
                <img className="appstore-button" src={appstoreButton} alt="appstore" />
            </a>
        </div>
    )
}