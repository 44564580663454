import React from 'react';
import './footer.css'

export default () => {
    return (
        <div className="footer-container">
            <p><a href="https://firebasestorage.googleapis.com/v0/b/getitdone-7312d.appspot.com/o/20200211%20GETITDONE_Privacy%20Policy.pdf?alt=media&token=045b285e-1080-47c4-9794-3b4624e8c14a">Privacy Policy</a></p>
            <p><a href="mailto:getitdone@rouk.io">Contact Us</a></p>
            <p>{'Copyright © 2020 GETITDONE'}</p>
        </div>
    )
}