import React from 'react';
import Buttons from './buttons/buttons';
import Hero from './hero/hero';
import Footer from './footer/footer';
import './landing.css'

export default (props) => {
    return (
        <div className="vertical-align-container">
            <div className="container">
                <Buttons />
                <Hero />
                <Footer />
            </div>
        </div>
    )
}