import React from 'react';
import phoneMock from './phone-mock.png';
import './hero.css'

export default () => {
    return (
        <div className="hero-container">
            <p className="hero-slogan">Most simple way to <span>be productive</span></p>
            <img className="hero-image" src={phoneMock} alt="phone-mock" />
            <div className="hero-text">
                <p>Everyone constantly checks their phone. Is it for messages, instagram, calendar or just for notifications.</p>
                <p>Avarage American checks their phone every 12 minutes.</p>
                <p>Every time you check your phone for notifications you’ll see your to-do list. That makes you want to complete all tasks as soon as possible.</p>
            </div>
        </div> 
    )
}